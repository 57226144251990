<template>
  <div class="lists">
    <div class="rowBox">
      <van-row>
        <van-col span="12" v-for="(item, index) in productList" :key="index">
          <div class="productBox">
            <div class="product" @click="getProductDetails(item.ProductNumber)">
              <van-image
                class="productImg"
                :src="imgUrlFilter(item.ProductPhoto)"
              >
                <template v-slot:error>
                  <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                </template>
              </van-image>

              <div class="productTitle">
                <div class="discount" v-if="item.IsCouponProduct">
                  {{ $t.discount }}
                </div>
                <div class="name">{{ item.ProductName }}</div>
              </div>
              <section class="info">
                <p class="unit">$</p>
                <p class="num">{{ Math.round(item.MinPrice * 100) / 100 }}</p>
                <!-- <p class="sale">{{ $t.piece(item.Sales) }}</p> -->
              </section>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col,Image } from "vant";
Vue.use(Row).use(Col).use(Image);

export default {
  name: "Lists",
  props: ["productList"],
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    //跳转详情页
    getProductDetails(ProductNumber) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin publicProduct {
  width: 97%;
  border-radius: 16px;
  box-shadow: 0 0 0px 1px #ece7e7;
  background-color: #ffffff;
  margin-right: 3%;
}
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin productInfo {
  padding: 0;
  margin: 0;
  text-align: center;
  @include spanNowrap;
}
.lists {
  // background-color: #fefefe;
  background-color: #f6f6f6;
  .rowBox {
    width: 95%;
    margin: 0 auto;
    ::v-deep .van-row {
      .van-col {
        margin-top: 3%;
        &:nth-child(even) {
          .productBox {
            .product {
              // background-color: red;
              @include publicProduct;
              margin-left: 3%;
            }
          }
        }
        &:nth-child(odd) {
          .productBox {
            .product {
              // background-color: red;
              margin: 0;
              @include publicProduct;
              margin-right: 3%;
            }
          }
        }
      }
    }
    .productBox {
      width: 100%;
      position: relative;
      // background-color: #ffffff;
      background-color: #f6f6f6;
      .productImg {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 49.5vw;
        overflow: hidden;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        .ImgIcon {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        ::v-deep .van-image__img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        ::v-deep .van-image__error {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }
      .productTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: normal;
        color: #000000;
        width: 90%;
        font-size: 12px;
        font-family: Helvetica;
        font-weight: 400;
        margin: 10px;
        height: 30px;
        .discount {
          vertical-align: middle;
          display: inline-block;
          background: red;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 12px;
          margin-right:5px;
        }
        .name {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .info {
        padding: 10px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        .unit {
          @include productInfo;
          font-size: 14px;
          color: #ff5852;
          width: 8%;
          font-weight: bold;
        }
        .num {
          @include productInfo;
          color: #ff5852;
          font-weight: bold;
          text-align: left;
          font-size: 18px;
          width: 42%;
        }
        .sale {
          @include productInfo;
          color: #a7a7a7;
          text-align: center;
          font-size: 14px;
          width: 50%;
        }
      }
      .shareBox {
        width: 86%;
        margin: 0 auto;
        margin-top: 7.5px;
        position: relative;
        padding-bottom: 6%;
        .shareImg {
          margin: 0;
          padding: 0;
          .shareImgIcon {
            width: 100%;
          }
        }
        .shareInfoBox {
          // position: absolute;
          // top: 0;
          width: 100%;
          //background-color: red;
          // line-height: 124%;
          background-image: url("~assets/img/home/icon_home_button.png");
          // background-repeat: no-repeat;
          background-size: 100% 100%;
          height: 66px;
          .shareLogo {
            width: 60%;
          }
          .shareInfo {
            font-size: 28px;
            color: #ffffff;
            margin-top: 0;
            text-align: left;
            @include spanNowrap;
            width: 80%;
          }
          .shareCol {
            margin-top: 0;
            @include spanNowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            display: -webkit-flex;
            -webkit-align-items: center;
            -webkit-justify-content: center;
          }
        }
      }
    }
  }
}
</style>
