import { render, staticRenderFns } from "./Lists.vue?vue&type=template&id=6d1dbef6&scoped=true"
import script from "./Lists.vue?vue&type=script&lang=js"
export * from "./Lists.vue?vue&type=script&lang=js"
import style0 from "./Lists.vue?vue&type=style&index=0&id=6d1dbef6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d1dbef6",
  null
  
)

export default component.exports